@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Regular'), local('Google-Sans-Regular'),
	url('../fonts/GoogleSans/GoogleSans-Regular.woff2') format('woff2'),
	url('../fonts/GoogleSans/GoogleSans-Regular.woff') format('woff'),
	url('../fonts/GoogleSans/GoogleSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Medium'), local('Google-Sans-Medium'),
	url('../fonts/GoogleSans/GoogleSans-Medium.woff2') format('woff2'),
	url('../fonts/GoogleSans/GoogleSans-Medium.woff') format('woff'),
	url('../fonts/GoogleSans/GoogleSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Bold'), local('Google-Sans-Bold'),
	url('../fonts/GoogleSans/GoogleSans-Bold.woff2') format('woff2'),
	url('../fonts/GoogleSans/GoogleSans-Bold.woff') format('woff'),
	url('../fonts/GoogleSans/GoogleSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Halvar Breitschrift';
	src: local('Halvar Breitschrift XBd'), local('Halvar-Breitschrift-XBd'),
	url('../fonts/HalvarBreit/HalvarBreit-XBd.woff2') format('woff2'),
	url('../fonts/HalvarBreit/HalvarBreit-XBd.woff') format('woff'),
	url('../fonts/HalvarBreit/HalvarBreit-XBd.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}