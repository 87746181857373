@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html, body{
	padding: 0;
	margin: 0;
	line-height: normal;
	font-weight: normal;
	background: #0B0B19;
	color: #ffffff;
	font-family: "Google Sans", sans-serif;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	select,
	textarea,
	input {
		font-size: 16px !important;
	}
}

main{
	position: relative;
	margin-top: 80px;
}

*{
	padding: 0;
	margin: 0;
	outline: none;
	box-sizing: border-box;
}

a{
	text-decoration: none;
	transition: .3s ease-in-out;
}

ul{
	padding: 0;
	margin: 0;
	list-style: none;
}

h1, h2, h3, h4 {
	padding: 0;
	margin: 0;
	font-family: "Halvar Breitschrift", sans-serif;
	font-weight: bold;
}

input, textarea{
	padding: 0;
	margin: 0;
	border: none;
	font-family: "Google Sans", sans-serif;
}

button{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	padding: 0;
	background: transparent;
	transition: .3s ease-in-out;
	cursor: pointer;
	font-family: "Google Sans", sans-serif;
}

form{
	width: 100%;
	position: relative;
}

img {
	max-width: 100%;
	height: auto;
	align-self: center
}

img.align-self {
	align-self: center;
}

.switch_host{
	background: #D80027;
	padding: 5px 10px;
	text-align: center;
	color: #ffffff;
	border-radius: 10px;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

html, #__next {min-height: 100%; height: 100%}
body {height: 100%; overflow-x: hidden}
header, footer {flex: 0 0 auto}
main {flex: 1 0 auto}
#__next {display: flex; flex-direction: column;}
@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Regular'), local('Google-Sans-Regular'),
	url(/_next/static/media/GoogleSans-Regular.151ff16e.woff2) format('woff2'),
	url(/_next/static/media/GoogleSans-Regular.9d2e79ff.woff) format('woff'),
	url(/_next/static/media/GoogleSans-Regular.39122e3e.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Medium'), local('Google-Sans-Medium'),
	url(/_next/static/media/GoogleSans-Medium.dea234d0.woff2) format('woff2'),
	url(/_next/static/media/GoogleSans-Medium.5f6415ed.woff) format('woff'),
	url(/_next/static/media/GoogleSans-Medium.7942093a.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Google Sans';
	src: local('Google Sans Bold'), local('Google-Sans-Bold'),
	url(/_next/static/media/GoogleSans-Bold.93fc8b3a.woff2) format('woff2'),
	url(/_next/static/media/GoogleSans-Bold.baea0733.woff) format('woff'),
	url(/_next/static/media/GoogleSans-Bold.7dd105c5.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Halvar Breitschrift';
	src: local('Halvar Breitschrift XBd'), local('Halvar-Breitschrift-XBd'),
	url(/_next/static/media/HalvarBreit-XBd.8f3a4a9f.woff2) format('woff2'),
	url(/_next/static/media/HalvarBreit-XBd.8f35fc06.woff) format('woff'),
	url(/_next/static/media/HalvarBreit-XBd.0b82a46a.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
